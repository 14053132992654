<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
        <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="560"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <v-form v-model="passwordValid" >
                    <h1>Resete sua senha</h1>
                    <p>Insira uma nova senha e a confirme nos campos abaixo.</p>
                    <v-text-field
                      class="mb-2"
                      label="Nova senha"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      outlined
                      v-model="password"
                      hint="No minimo 8 caracteres"
                      :append-icon="passwordVisibleIcon"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                      counter
                      :rules="[rules.required, rules.minimumLength]"
                    ></v-text-field>
                    <v-text-field
                      class="mb-2"
                      label="Confirmar nova senha"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      outlined
                      v-model="confirmPassword"
                      counter
                      :rules="[rules.required, rules.samePassword]"
                    ></v-text-field>
                    <v-btn block color="accent" @click="resetPassword" :disabled="!passwordValid">
                      Resetar senha
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import formMixin from '@/util/mixins/formMixin'

export default {
  name: 'ResetPasswordScreen',

  mixins: [formMixin],

  data() {
    return {
      token: this.$route.query.token,
      email: this.$route.query.email,
      password: null,
      confirmPassword: null,
      passwordValid: null,

      rules: {
        minimumLength: v => v.length >= 8 || 'A senha deve conter no minimo 8 caracteres.',
        samePassword: v => v == this.password || 'A senhas não coincidem.',
      },
      
      isPasswordVisible: false,
    }
  },

   computed: {
    passwordVisibleIcon() {
      return this.isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline
    },
  },

  methods: {
    resetPassword() {
      this.$http.$post('/auth/reset-password', {
        email: this.email,
        token: this.token,
        password: this.password
      })
      .then( response => {
        console.log(response)
      })
      .catch( error => {
        console.log(error)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
